<template>
  <div class="mobile-page">
    <div class="header">
      <img
        src="../public/images/new/backicon.png"
        class="back"
        @click="$router.go(-1)"
      />
      <div class="title">{{ title }}</div>
      <span class="right">
        <slot name="header-right" />
      </span>
    </div>
    <div class="scroll">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style lang="less" scoped>
.mobile-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background: url("../public/images/page_bg.png") center no-repeat;
  background-size: 100% 100%;
  .header {
    flex: 0 0 auto;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    background-color: #000;
    .back {
      flex: 0 0 auto;
      width: 14px;
      height: 22px;
      object-fit: contain;
    }
    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      text-align: center;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .scroll {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }
}
</style>
